// TODO: przywrocic obnsluge isAttachmentsAllFetched
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { PropsWithChildren, useEffect, useState } from "react";
import { connect } from "react-redux";
import "moment/locale/pl";
import { checkText } from "smile2emoji";
import { Autolinker } from "autolinker";
import { Badge, Button, Card, Col, Row, Space, Spin } from "antd";

import { useParams } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import {
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import HTMLReactParser from "html-react-parser";
import { AppState } from "../../../reducers";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import {
  getArchiveMessages,
  getChatAttachments,
  getChatRooms,
  getChatTickets,
  getChatUsers,
  getChatUserTags,
  getLinkMessages,
  getMessages,
  getNewMessages,
  postChatMessage,
  postChatReaction,
  setAttachments,
  setNewMessagesCount,
  setSelectedRoom,
} from "../../../actions/chat";
import { MessagesList } from "../../Shared/Chat/MessagesList";
import { MessageInput } from "../../Shared/Chat/MessageInput";
import { isImageFileType, isTimeDiffMoreThan } from "../../../utils/common";
import { ChatUsers } from "../../Shared/Chat/ChatUsers";
import useIsMobile from "../../../hooks/useIsMobile";
import { ChatRooms } from "../../Shared/Chat/ChatRooms";

interface IRouteParams {
  id: string;
  room: string;
}

const ChatPage = (props: PropsWithChildren<any>): JSX.Element => {
  const {
    getMessagesAction,
    getChatRoomsAction,
    getNewMessagesAction,
    getChatUsersAction,
    setNewMessagesCountAction,
    postMessageAction,
    postMessageReactionAction,
    selectedRoom,
    rooms,
    roomsCount,
    messages,
    users,
    tickets,
    tags,
    imageAttachments,
    displayNotification,
    isFetching,
    isUsersFetching,
    isRoomsFetching,
    getArchiveMessagesAction,
    isSending,
    loggedUser,
    isArchiveFetching,
    getTicketsAction,
    getChatUsersTagsAction,
    getLinkMessagesAction,
    setImageAttachments,
    getImagesAttachments,
    isAllAttachmentsFetched,
    newMessagesCount,
    setSelectedRoomAction,
  } = props;

  const { id, room } = useParams<IRouteParams>();
  const isMobile = useIsMobile("lg");
  const [roomTitle, setRoomTitle] = useState<string>("");
  const [images, setImages] = useState<any[]>([]);
  const [imagePreviewIndex, setImagePreviewIndex] = useState(0);
  const [isImagePreviewVisible, setImagePreviewVisible] = useState(false);
  const [isShowMobileRooms, showMobileRooms] = useState(false);

  useEffect(() => {
    if (room) {
      setSelectedRoomAction(parseInt(room, 10));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room]);

  useEffect(() => {
    if (rooms.length > 0 && !selectedRoom) {
      setSelectedRoomAction(rooms[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms]);

  useEffect(() => {
    let interval: any;
    getChatRoomsAction();
    getTicketsAction();
    if (selectedRoom) {
      getChatUsersAction(selectedRoom);
      getChatUsersTagsAction(selectedRoom);
      if (id) {
        // todo: refetch messages at id change?
        getLinkMessagesAction(selectedRoom, id);
      } else {
        getMessagesAction(selectedRoom);
      }

      if (rooms) {
        const filteredRoom = rooms.filter(
          (roomObj: any) => roomObj.id === selectedRoom
        );
        if (filteredRoom.length)
          setRoomTitle(
            rooms.filter((roomObj: any) => roomObj.id === selectedRoom)[0].name
          );
      }

      interval = setInterval(() => {
        getChatRoomsAction();
        getChatUsersAction(selectedRoom);
        getNewMessagesAction(selectedRoom);
      }, parseInt(process.env.REACT_APP_DEFAULT_INTERVAL || "2000", 10)); // 2000
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoom]);

  useEffect(() => {
    document.body.classList.add("scroll-disabled");
    document.body.classList.add("tooltip-9999-z-index");

    return () => {
      document.body.classList.remove("scroll-disabled");
      document.body.classList.remove("tooltip-9999-z-index");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // setNewMessagesCountAction(0);
    if (selectedRoom && messages.length) {
      setImageAttachments(
        selectedRoom,
        createImageAttachmentsListFromMessagesList(messages)
      );
    }
  }, [setNewMessagesCountAction, messages, setImageAttachments, selectedRoom]);

  const handleSubmit = (
    message: string,
    attachments: [],
    callback: () => void
  ) => {
    let parsedMessage = parseAllMentions(message);
    parsedMessage = parseTagsMentions(parsedMessage);
    parsedMessage = parseUsersMentions(parsedMessage);
    parsedMessage = parseTicketLinksBeforeSave(parsedMessage);
    parsedMessage = parseSmilesToEmojis(parsedMessage);
    parsedMessage = parseLinks(parsedMessage);
    const userMentionsIds = getMentionsIds(parsedMessage, "userId");
    const tagMentionsIds = getMentionsIds(parsedMessage, "tagId");
    const shouldMentionAllUsers = shouldMentionAll(parsedMessage);
    callback();
    postMessageAction(
      selectedRoom,
      parsedMessage,
      userMentionsIds,
      tagMentionsIds,
      shouldMentionAllUsers,
      attachments
    ).then(() => {
      getMessagesAction(selectedRoom);
    });
  };

  const handleMessageReaction = (
    messageId: string | number,
    reaction: string
  ) => {
    postMessageReactionAction(messageId, selectedRoom, reaction);
  };

  const sortedMessages = messages.sort(
    (a: any, b: any) => b.dateAdd - a.dateAdd
  );

  const findImagePreviewIndex = (fileId: number) => {
    return imageAttachments.findIndex((image: any) => image.id === fileId);
  };

  const openImagePreview = (fileId: number) => {
    setImagePreviewIndex(
      imageAttachments.findIndex((image: any) => image.id === fileId)
    );
    setImagePreviewVisible(true);
  };
  const groupedMessages = groupMessages(sortedMessages);

  const tagsSuggestions = mapTagsToSuggestions(tags);
  const usersSuggestions = mapUsersToSuggestions(users, loggedUser);
  const ticketsSuggestions = mapTicketsToSuggestions(tickets);

  return (
    <Can renderError type="chat">
      <div className="chat chat-page">
        <MetaTitle title="Czat" displayBadge={displayNotification} />
        <Row gutter={[8, 8]} align="stretch" style={{ height: "100%" }}>
          <Col
            xs={{ order: 1, span: 24 }}
            sm={{ order: 1, span: 24 }}
            lg={{ order: 3, span: 6 }}
            xl={{ order: 3, span: 4 }}
            xxl={{ order: 3, span: 4 }}
          >
            <Card
              title={!isMobile && "Użytkownicy kanału"}
              bordered={false}
              size="small"
              bodyStyle={{
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "row",
                padding: 8,
              }}
            >
              <ChatUsers
                isMobile={isMobile}
                users={users}
                isLoading={isUsersFetching && !users.length}
              />
              {isMobile && (
                <Space
                  style={{ marginRight: 8, cursor: "pointer" }}
                  onClick={() => showMobileRooms(!isShowMobileRooms)}
                >
                  <Badge count={newMessagesCount} size="small" color="orange">
                    <Button
                      type="link"
                      icon={
                        !isShowMobileRooms ? (
                          <MenuUnfoldOutlined />
                        ) : (
                          <MenuFoldOutlined />
                        )
                      }
                    />
                  </Badge>
                  <Text ellipsis style={{ maxWidth: 80 }}>
                    {roomTitle}
                  </Text>
                </Space>
              )}
            </Card>
          </Col>
          <Col
            xs={{ order: 2, span: 24 }}
            sm={{ order: 2, span: 24 }}
            lg={{ order: 2, span: 12 }}
            xl={{ order: 2, span: 16 }}
            xxl={{ order: 2, span: 16 }}
          >
            <Card
              bordered={false}
              size="small"
              style={{ height: "100%" }}
              bodyStyle={{
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              {isShowMobileRooms && (
                <ChatRooms
                  isMobile={false}
                  rooms={rooms}
                  count={roomsCount || []}
                  isLoading={isRoomsFetching && !rooms.length}
                  selectedRoom={selectedRoom}
                  selectRoom={(roomId: number | string) => {
                    setSelectedRoomAction(roomId);
                    showMobileRooms(false);
                  }}
                />
              )}

              {!isShowMobileRooms && (
                <MessagesList
                  linkMessageId={id}
                  messagesLength={messages.length}
                  users={users}
                  messages={groupedMessages}
                  isFetching={isFetching}
                  loggedUser={loggedUser}
                  getArchiveMessages={(timestamp) =>
                    getArchiveMessagesAction(selectedRoom, timestamp)
                  }
                  isArchiveFetching={isArchiveFetching}
                  openImagePreview={openImagePreview}
                  selectedRoom={selectedRoom}
                  postMessageReaction={handleMessageReaction}
                />
              )}
              {!isShowMobileRooms && (
                <MessageInput
                  users={usersSuggestions}
                  tickets={ticketsSuggestions}
                  tags={tagsSuggestions}
                  isSending={isSending}
                  handleSubmit={(message, attachments, callback) =>
                    handleSubmit(message, attachments, callback)
                  }
                />
              )}
            </Card>
          </Col>
          {!isMobile && (
            <Col
              xs={{ order: 1, span: 24 }}
              sm={{ order: 1, span: 24 }}
              lg={{ order: 1, span: 6 }}
              xl={{ order: 1, span: 4 }}
              xxl={{ order: 1, span: 4 }}
            >
              <Card
                title={!isMobile && "Kanały"}
                bordered={false}
                size="small"
                bodyStyle={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  padding: 8,
                }}
              >
                <ChatRooms
                  isMobile={false}
                  rooms={rooms}
                  count={roomsCount || []}
                  isLoading={isRoomsFetching && !rooms.length}
                  selectedRoom={selectedRoom}
                  selectRoom={setSelectedRoomAction}
                />
              </Card>
            </Col>
          )}
        </Row>
        {isImagePreviewVisible && (
          <Lightbox
            reactModalStyle={{ zIndex: 10000 }}
            mainSrc={getImageUrl(
              imageAttachments[imagePreviewIndex]?.id,
              false
            )}
            prevSrc={
              imageAttachments.length - 1 > imagePreviewIndex
                ? getImageUrl(
                    imageAttachments[
                      (imagePreviewIndex + 1) % imageAttachments.length
                    ]?.id,
                    false
                  )
                : undefined
            }
            nextSrc={
              imagePreviewIndex > 0
                ? getImageUrl(
                    imageAttachments[
                      (imagePreviewIndex + imageAttachments.length - 1) %
                        imageAttachments.length
                    ]?.id,
                    false
                  )
                : undefined
            }
            // prevSrc={
            //   imageAttachments.length - 1 > imagePreviewIndex
            //     ? `${process.env.REACT_APP_API_URL}/attachments/file/${
            //         imageAttachments[
            //           (imagePreviewIndex + 1) % imageAttachments.length
            //         ].id
            //       }`
            //     : undefined
            // }
            onCloseRequest={() => setImagePreviewVisible(false)}
            onMoveNextRequest={() => {
              setImagePreviewIndex(
                (imagePreviewIndex + images.length - 1) %
                  imageAttachments.length
              );
            }}
            imageCaption={
              imageAttachments.length > 0
                ? HTMLReactParser(
                    imageAttachments[imagePreviewIndex].chats?.comment || ""
                  )
                : null
            }
            onAfterOpen={() => {
              if (imagePreviewIndex + 10 > imageAttachments.length) {
                getImagesAttachments(
                  selectedRoom,
                  imageAttachments[imageAttachments.length - 1].id
                );
              }
            }}
            onMovePrevRequest={() => {
              if (imagePreviewIndex + 10 > imageAttachments.length) {
                getImagesAttachments(
                  selectedRoom,
                  imageAttachments[imageAttachments.length - 1].id
                );
              }
              setImagePreviewIndex(
                (imagePreviewIndex + 1) % imageAttachments.length
              );
            }}
          />
        )}
      </div>
    </Can>
  );
};

const groupMessages = (messages: any[]) => {
  const posts = messages.reduce((grouped, post) => {
    if (grouped.length === 0) grouped.unshift([post]);
    else if (
      grouped[0][0].user.id === post.user.id &&
      !isTimeDiffMoreThan(grouped[0][0].dateAdd, post.dateAdd, 2, "minute")
    )
      grouped[0].push(post);
    else grouped.unshift([post]);
    return grouped;
  }, []);
  posts.forEach((msgs: any[]) => msgs.reverse());
  return posts.reverse();
};

const createImageAttachmentsListFromMessagesList = (messages: any[]) => {
  const images: any[] = [];
  messages.forEach((post: any) => {
    post?.attachment?.forEach((file: any) => {
      if (isImageFileType(file.fileMimeType)) {
        images.push({
          ...file,
          chats: { comment: post.comment },
        });
      }
    });
  });

  return images.reverse();
};

const mapUsersToSuggestions = (users: any[], loggedUser: any) => {
  return users
    .filter((user) => user.id !== loggedUser.id)
    .map((user) => {
      return { ...user, display: `${user.name} ${user.surname}` };
    });
};
const mapTagsToSuggestions = (tags: any[]) => {
  return [
    { id: "all", display: "Wszyscy" },
    ...tags.map((tag) => {
      return { ...tag, display: `${tag.name}`, id: `t-${tag.id}` };
    }),
  ];
};

const mapTicketsToSuggestions = (tickets: any[]) => {
  return tickets.map((ticket) => {
    return {
      ...ticket,
      display: `${ticket.displayId} -  ${ticket.title}`,
      id: `${ticket.group?.id}/${ticket.id}`,
    };
  });
};
/*
@[__display__](user:__id__)
 */

const parseUsersMentions = (post: string) => {
  let parsed = post;
  parsed = parsed.split("@@@__").join('<strong data-userId="');
  parsed = parsed.split("^^__").join('">@');
  parsed = parsed.split("@@@^^^").join("</strong>");
  return parsed;
};

const parseTagsMentions = (post: string) => {
  let parsed = post;
  parsed = parsed.split("@@@__t-").join('<strong data-tagId="');
  parsed = parsed.split("^^__").join('">@');
  parsed = parsed.split("@@@^^^").join("</strong>");
  return parsed;
};

const parseAllMentions = (post: string) => {
  let parsed = post;
  parsed = parsed.split("@@@__all").join('<strong data-mentionType="all');
  parsed = parsed.split("^^__").join('">@');
  parsed = parsed.split("@@@^^^").join("</strong>");
  return parsed;
};

const parseSmilesToEmojis = (post: string) => checkText(post);
const parseLinks = (post: string) => Autolinker.link(post, { newWindow: true });
const parseTicketLinksBeforeSave = (post: string) => {
  let parsed = post;
  parsed = parsed.split("###__").join('<a href="/group/');
  parsed = parsed.split("??__").join('">#');
  parsed = parsed.split("###^^^").join("</a>");
  return parsed;
};

const getMentionsIds = (post: string, type: "userId" | "tagId") => {
  const mentionsIds: number[] = [];
  const temp = document.createElement("p");
  temp.innerHTML = post;
  const mentions = temp.querySelectorAll(`[data-${type}]`);
  mentions.forEach((mention) => {
    const id = mention.getAttribute(`data-${type}`);
    if (id) {
      const mentionId = parseInt(id, 10);
      if (!mentionsIds.includes(mentionId)) mentionsIds.push(mentionId);
    }
  });

  return mentionsIds;
};

const shouldMentionAll = (post: string) => {
  let mentionAll = false;
  const temp = document.createElement("p");
  temp.innerHTML = post;
  const mentions = temp.querySelectorAll(`[data-mentionType]`);
  mentions.forEach((mention) => {
    const id = mention.getAttribute(`data-mentionType`);

    if (id === "all") {
      mentionAll = true;
    }
  });

  return mentionAll;
};

const getImageUrl = (id: number, thumb: boolean) => {
  return `${process.env.REACT_APP_API_URL}attachments/file/${id}${
    thumb ? "/thumb" : null
  }`;
};

const mapDispatchToProps = {
  getChatRoomsAction: getChatRooms,
  getChatUsersAction: getChatUsers,
  getChatUsersTagsAction: getChatUserTags,
  getMessagesAction: getMessages,
  getLinkMessagesAction: getLinkMessages,
  getTicketsAction: getChatTickets,
  getArchiveMessagesAction: getArchiveMessages,
  postMessageAction: postChatMessage,
  getNewMessagesAction: getNewMessages,
  setNewMessagesCountAction: setNewMessagesCount,
  setImageAttachments: setAttachments,
  getImagesAttachments: getChatAttachments,
  setSelectedRoomAction: setSelectedRoom,
  postMessageReactionAction: postChatReaction,
};

const mapStateToProps = (state: AppState) => {
  return {
    loggedUser: state.auth.logged,
    users: state.chat.selectedRoom
      ? state.chat.users[state.chat.selectedRoom] || []
      : [],
    tickets: state.chat.tickets,
    tags: state.chat.tags,
    isUsersFetching: state.chat.isUsersFetching,
    isRoomsFetching: state.chat.isRoomsFetching,
    isTagsFetching: state.chat.isTagsFetching,
    isTicketsFetching: state.chat.isTicketsFetching,
    isArchiveFetching: state.chat.isArchiveFetching,
    selectedRoom: state.chat.selectedRoom,
    rooms: state.chat.rooms,
    roomsCount: state.chat.newMessagesRoomsCount,
    newMessagesCount: state.chat.newMessagesCount,
    messages: state.chat.selectedRoom
      ? state.chat.messages[state.chat.selectedRoom] || []
      : [],
    isFetching: state.chat.isFetching,
    isSending: state.chat.isSending,
    displayNotification: state.notifications.newNotificationIndicator,
    imageAttachments: state.chat.selectedRoom
      ? state.chat.attachments[state.chat.selectedRoom] || []
      : [],
    isAllAttachmentsFetched: state.chat.allAttachmentsFetched,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatPage);
