import React, { PropsWithChildren, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Alert, Card, Col, Layout, Row, Space } from "antd";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import logo from "../../../assets/images/logo_white.png";
import ResetPasswordForm from "./ResetPasswordForm";
import { signUp } from "../../../actions/auth";
import { AppState } from "../../../reducers";
import { MetaTitle } from "../../Shared/MetaTitle";
import AuthService from "../../../services/auth-service";

interface IResetPasswordForm {
  isRequest: boolean;
  isLogged: boolean;

  signUpAction: (
    username: string,
    password: string,
    remember: boolean
  ) => Promise<void>;
}

interface ILocationStateType {
  from: { pathname: string };
}
interface IRouteParams {
  token: string;
}

const Login: React.FC<PropsWithChildren<IResetPasswordForm>> = (
  props: PropsWithChildren<IResetPasswordForm>
): JSX.Element => {
  const { Content } = Layout;
  const location = useLocation<ILocationStateType>();
  const history = useHistory();

  const { token } = useParams<IRouteParams>();

  const { signUpAction, isRequest, isLogged } = props;
  const [isTokenValid, setIsTokenValid] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTokenCheckRequest, setIsTokenCheckRequest] = useState<boolean>(
    false
  );
  const [isSent, setIsSent] = useState<boolean>(false);
  useEffect(() => {
    if (token) {
      setIsTokenCheckRequest(true);
      AuthService.checkResetToken(token)
        .then(() => {
          setIsTokenValid(true);
        })
        .catch(() => setIsTokenValid(false))
        .finally(() => {
          setIsTokenCheckRequest(false);
        });
    }
  }, [token]);

  const handleSubmit = (
    password: string,
    repeat_password: string,
    setStatus: (status: string) => void
  ) => {
    setIsLoading(true);
    AuthService.resetPassword(password, repeat_password, token)
      .then(() => {
        setIsSent(true);
        setStatus("Hasło zmienione. Możesz się już zalogować");
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        if (error.response?.status === 400) {
          setStatus("Link aktywacyjny wygasł lub token nie istnieje");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLogged) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: location },
        }}
      />
    );
  }

  return (
    <Layout>
      <MetaTitle title="Zmień hasło" displayBadge={false} />
      <Content className="auth-login">
        <Row justify="center" gutter={16}>
          <Col span={24} className="auth-login__logo-container">
            <img src={logo} className="auth-login__logo" alt="logo" />
          </Col>
        </Row>
        <Row
          justify="center"
          align="middle"
          className="auth-login__container"
          gutter={[16, 16]}
        >
          <Col
            xs={{ order: 1, span: 24 }}
            sm={{ order: 1, span: 24 }}
            md={{ order: 2, span: 24 }}
          >
            <Card className="auth-login__card" bordered title="Zmień hasło">
              {isTokenCheckRequest ? (
                <Space
                  align="center"
                  style={{
                    padding: 8,
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <LoadingOutlined spin />
                </Space>
              ) : null}

              {!isTokenValid && (
                <Alert
                  message="Link aktywacyjny wygasł lub token nieistnieje."
                  type="error"
                  style={{ marginBottom: 8 }}
                />
              )}
              {isTokenValid && !isTokenCheckRequest && (
                <ResetPasswordForm
                  isSent={isSent}
                  isTokenValid={isTokenValid}
                  handleSubmit={handleSubmit}
                  isRequest={isLoading}
                />
              )}
            </Card>
          </Col>
        </Row>
        <Row
          justify="center"
          className="auth-login__copyrights copyrights"
          gutter={16}
        >
          <Col span={24}>
            <p className="copyrights__text">
              © 2015-2023 jobin.to v0.0.121 22.01
            </p>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isRequest: state.auth.isRequest,
    isLogged: state.auth.isLogged,
  };
};

const mapDispatchToProps = {
  signUpAction: signUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
