import React, { PropsWithChildren, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Alert, Button, Card, Col, Layout, Row, Space } from "antd";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import logo from "../../../assets/images/logo_white.png";
import ResetPasswordForm from "./ResetPasswordForm";
import { signUp } from "../../../actions/auth";
import { AppState } from "../../../reducers";
import { MetaTitle } from "../../Shared/MetaTitle";
import AuthService from "../../../services/auth-service";
import AnswerStatus from "./AnswerStatus";

interface IResetPasswordForm {
  isRequest: boolean;
  isLogged: boolean;

  signUpAction: (
    username: string,
    password: string,
    remember: boolean
  ) => Promise<void>;
}

interface ILocationStateType {
  from: { pathname: string };
}
interface IRouteParams {
  token: string;
}

const Invite: React.FC<PropsWithChildren<IResetPasswordForm>> = (
  props: PropsWithChildren<IResetPasswordForm>
): JSX.Element => {
  const { Content } = Layout;
  const location = useLocation<ILocationStateType>();
  const history = useHistory();

  const { token } = useParams<IRouteParams>();

  const { signUpAction, isRequest, isLogged } = props;
  const [userAnswer, setUserAnswer] = useState<boolean | null>(null);
  const [inviteStatus, setInviteStatus] = useState<string>("");
  const [shouldChangePassword, setShouldChangePassword] = useState<boolean>(
    false
  );
  const [isTokenValid, setIsTokenValid] = useState<boolean>(true);
  const [company, setCompany] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTokenCheckRequest, setIsTokenCheckRequest] = useState<boolean>(
    false
  );
  const [isSent, setIsSent] = useState<boolean>(false);
  useEffect(() => {
    if (token) {
      setIsTokenCheckRequest(true);
      AuthService.checkInviteToken(token)
        .then((res) => {
          setIsTokenValid(true);
          setCompany(res.data?.company);
          setShouldChangePassword(res.data?.changePassword);
        })
        .catch(() => setIsTokenValid(false))
        .finally(() => {
          setIsTokenCheckRequest(false);
        });
    }
  }, [token]);

  const handleUserAnswer = (value: boolean) => {
    if (shouldChangePassword) {
      setUserAnswer(value);

      if (!value) {
        AuthService.declineInvite(token)
          .then(() => {
            setIsSent(true);
            setInviteStatus("Zaproszenie odrzucone");
          })
          // eslint-disable-next-line consistent-return
          .catch((error) => {
            if (error.response?.status === 400) {
              setIsSent(true);
              setInviteStatus(
                "Link z zaproszeniem wygasł lub token nie istnieje"
              );
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } else if (value) {
      AuthService.acceptInvite(token)
        .then(() => {
          setIsSent(true);
          setUserAnswer(value);
          setInviteStatus("Hasło zmienione. Możesz się już zalogować");
        })
        // eslint-disable-next-line consistent-return
        .catch((error) => {
          if (error.response?.status === 400) {
            setIsSent(true);
            setUserAnswer(value);
            setInviteStatus(
              "Link z zaproszeniem wygasł lub token nie istnieje"
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      AuthService.declineInvite(token)
        .then(() => {
          setIsSent(true);
          setInviteStatus("Zaproszenie odrzucone");
        })
        // eslint-disable-next-line consistent-return
        .catch((error) => {
          if (error.response?.status === 400) {
            setIsSent(true);
            setInviteStatus(
              "Link z zaproszeniem wygasł lub token nie istnieje"
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleSubmit = (
    password: string,
    repeat_password: string,
    setStatus: (status: string) => void
  ) => {
    setIsLoading(true);
    AuthService.setInvitePassword(password, repeat_password, token)
      .then(() => {
        setIsSent(true);
        setStatus("Hasło zmienione. Możesz się już zalogować");
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        if (error.response?.status === 400) {
          setStatus("Link aktywacyjny wygasł lub token nie istnieje");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // if (isLogged) {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: "/",
  //         state: { from: location },
  //       }}
  //     />
  //   );
  // }

  return (
    <Layout>
      <MetaTitle title="Zaproszenie" displayBadge={false} />
      <Content className="auth-login">
        <Row justify="center" gutter={16}>
          <Col span={24} className="auth-login__logo-container">
            <img src={logo} className="auth-login__logo" alt="logo" />
          </Col>
        </Row>
        <Row
          justify="center"
          align="middle"
          className="auth-login__container"
          gutter={[16, 16]}
        >
          <Col
            xs={{ order: 1, span: 24 }}
            sm={{ order: 1, span: 24 }}
            md={{ order: 2, span: 24 }}
          >
            <Card className="auth-login__card" bordered title="Zaproszenie">
              {isTokenCheckRequest ? (
                <Space
                  align="center"
                  style={{
                    padding: 8,
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <LoadingOutlined spin />
                </Space>
              ) : null}

              {!isTokenValid && (
                <Alert
                  message="Link z zaproszeniem wygasł lub token nieistnieje."
                  type="error"
                  style={{ marginBottom: 8 }}
                />
              )}
              {isTokenValid &&
                !isTokenCheckRequest &&
                !isSent &&
                company &&
                userAnswer === null && (
                  <div className="invitation">
                    <img
                      src={company?.img}
                      alt={company?.name}
                      className="invitation__image"
                    />
                    <p className="invitation__text">
                      Zostałeś zaproszony przez <strong>{company?.name}</strong>{" "}
                      do systemu Jobin. Czy akceptujesz zaproszenie?
                    </p>
                    <Space>
                      <Button onClick={() => handleUserAnswer(false)}>
                        Odrzuć
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => handleUserAnswer(true)}
                      >
                        Akceptuj
                      </Button>
                    </Space>
                  </div>
                )}

              {isTokenValid &&
                !isTokenCheckRequest &&
                userAnswer === true &&
                shouldChangePassword && (
                  <ResetPasswordForm
                    isSent={isSent}
                    isTokenValid={isTokenValid}
                    handleSubmit={handleSubmit}
                    isRequest={isLoading}
                  />
                )}

              <AnswerStatus
                isRequest={isLoading}
                isTokenValid={isTokenValid}
                isSent={isSent}
                inviteStatus={userAnswer}
              />
            </Card>
          </Col>
        </Row>
        <Row
          justify="center"
          className="auth-login__copyrights copyrights"
          gutter={16}
        >
          <Col span={24}>
            <p className="copyrights__text">
              © 2015-2023 jobin.to v0.0.121 22.01
            </p>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isRequest: state.auth.isRequest,
    isLogged: state.auth.isLogged,
  };
};

const mapDispatchToProps = {
  signUpAction: signUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
