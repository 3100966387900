import React, { useState } from "react";
import { Avatar, Button, Col, Modal, Popover, Row, Space, Tooltip } from "antd";
import classnames from "classnames";
import HTMLReactParser from "html-react-parser";
import Text from "antd/lib/typography/Text";
import { CloudDownloadOutlined, SmileOutlined } from "@ant-design/icons";
import FileDownload from "js-file-download";
import { ReactionBarSelector } from "@charkour/react-reactions";
import EmojiPicker, { EmojiStyle } from "emoji-picker-react";
import ReactAudioPlayer from "react-audio-player";
import {
  defaultReactions,
  emojisCategories,
  isAudioFileType,
  isImageFileType,
  isImageGif,
  makeAvatarName,
  stringToColour,
  timestampToDate,
} from "../../../utils/common";
import TicketService from "../../../services/tickets-service";
import ChatReactions from "./ChatReactions";

interface IProps {
  linkMessageId: string;
  messages: any[];
  author: any;
  isOwn: boolean;
  date: number;
  isOnline: boolean;
  isIdle: boolean;
  openImagePreview: (fileId: number) => void;
  postMessageReaction: (messageId: string | number, reaction: string) => void;
}

const MessageBubble = (props: IProps) => {
  const {
    linkMessageId,
    author,
    isOwn,
    date,
    messages,
    isOnline,
    isIdle,
    openImagePreview,
    postMessageReaction,
  } = props;
  const [downloadId, setDownloadId] = useState<number | null>(null);
  const [visibleReactionBar, setVisibleReactionBar] = useState<number | null>(
    null
  );
  const [visibleEmojiReaction, setVisibleEmojiReaction] = useState<
    number | null
  >(null);

  const handleDownload = (file: any) => {
    setDownloadId(file.id);
    TicketService.downloadFile(file.id).then((response) => {
      setDownloadId(null);
      FileDownload(response.data, file.fileName);
    });
  };

  return (
    <Row
      gutter={[16, 16]}
      align="top"
      className="chat__message"
      wrap={false}
      // style={{ zIndex: messages[0].id }}
    >
      <Col flex="50px">
        <Avatar
          size={true ? 26 : 40}
          src={author.img || null}
          className={classnames({
            "user-app__avatar": true,
            user__avatar: true,
            "user__avatar--online": isOnline,
            "user__avatar--idle": isIdle,
          })}
          style={{
            fontSize: 14,
            color: "#fefefe",
            boxSizing: "content-box",
            backgroundColor: stringToColour(author.email),
            marginTop: "8px",
          }}
        >
          {makeAvatarName(author.delated, author.name, author.surname)}
        </Avatar>
      </Col>
      <Col>
        <Row>
          <Col>
            <small className="chat__author">
              {author.name} {author.surname}{" "}
              <span className="chat__date">
                {timestampToDate(date * 1000, "dd.MM.yyyy HH:mm")}
              </span>
            </small>
          </Col>
        </Row>
        <Row>
          <Col className="chat__bubble-group">
            {messages.map((message, index) => {
              return (
                <div className="chat__bubble-wrapper">
                  <div
                    id={`bubble-${message.id}`}
                    key={`bubble-${message.id}`}
                    className={classnames({
                      chat__bubble: true,
                      "chat__bubble--self": isOwn,
                      "chat__bubble--with-reactions":
                        message.chatReactions.length > 0,
                      "chat__bubble--linked": linkMessageId
                        ? message.id === parseInt(linkMessageId, 10)
                        : false,
                    })}
                  >
                    {message.attachment.length > 0 && (
                      <div className="chat__images">
                        {/* eslint-disable-next-line array-callback-return,consistent-return */}
                        {message.attachment.map((attachment: any) => {
                          console.log(attachment.fileMimeType);
                          if (isImageFileType(attachment.fileMimeType)) {
                            return (
                              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                              <img
                                className="chat__image"
                                src={
                                  isImageGif(attachment.fileMimeType)
                                    ? `${process.env.REACT_APP_API_URL}attachments/file/${attachment.id}`
                                    : `${process.env.REACT_APP_API_URL}attachments/file/${attachment.id}/thumb`
                                }
                                alt="test"
                                loading="lazy"
                                onClick={() => openImagePreview(attachment.id)}
                              />
                            );
                          }
                          if (isAudioFileType(attachment.fileMimeType)) {
                            return (
                              // eslint-disable-next-line  jsx-a11y/media-has-caption
                              <ReactAudioPlayer
                                preload="none"
                                controls
                                className="chat__audio-player audio-player"
                              >
                                <source
                                  type="audio/mp3"
                                  src={`${process.env.REACT_APP_API_URL}attachments/file/${attachment.id}/audio`}
                                />
                              </ReactAudioPlayer>
                            );
                          }
                        })}
                      </div>
                    )}

                    {HTMLReactParser(message.comment || "")}

                    {/* eslint-disable-next-line array-callback-return,consistent-return */}
                    {message.attachment.map((attachment: any) => {
                      if (
                        !isImageFileType(attachment.fileMimeType) &&
                        !isAudioFileType(attachment.fileMimeType)
                      ) {
                        return (
                          <Space
                            style={{
                              width: "100%",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Text
                              onClick={() => handleDownload(attachment)}
                              className="ticket-history ticket-history__link"
                            >
                              {attachment.fileOriginalName}
                            </Text>

                            <Tooltip title="Pobierz załacznik">
                              <Button
                                size="small"
                                icon={<CloudDownloadOutlined />}
                                onClick={() => handleDownload(attachment)}
                              />
                            </Tooltip>
                          </Space>
                        );
                      }
                    })}
                    <ChatReactions
                      reactions={message.chatReactions.map((reaction: any) => {
                        return {
                          key: reaction.content,
                          user: `${reaction?.user?.name} ${reaction?.user?.surname}`,
                        };
                      })}
                      onClick={(key) => postMessageReaction(message.id, key)}
                    />
                  </div>
                  <div className="chat__bubble-options">
                    <Popover
                      visible={
                        visibleReactionBar === message.id ||
                        visibleEmojiReaction === message.id
                      }
                      onVisibleChange={(visible: boolean) => {
                        setVisibleReactionBar(visible ? message.id : null);
                      }}
                      placement="right"
                      style={{ background: "transparent", padding: 0 }}
                      content={
                        // eslint-disable-next-line no-nested-ternary

                        <ReactionBarSelector
                          iconSize={20}
                          reactions={defaultReactions}
                          onSelect={(selected) => {
                            if (selected === "more") {
                              setVisibleReactionBar(null);
                              setVisibleEmojiReaction(message.id);
                              return;
                            }

                            setVisibleReactionBar(null);
                            postMessageReaction(message.id, selected);
                          }}
                        />
                      }
                      trigger="click"
                      className="chat__reaction-popover"
                      getPopupContainer={(trigger) =>
                        trigger?.parentElement?.parentElement as HTMLElement
                      }
                    >
                      <Button
                        size="middle"
                        type="text"
                        icon={<SmileOutlined color="gray" />}
                      />
                    </Popover>
                  </div>
                  <Modal
                    bodyStyle={{ padding: 0 }}
                    maskStyle={{ zIndex: 1102 }}
                    closable={false}
                    footer={null}
                    visible={visibleEmojiReaction === message.id}
                    onOk={() => setVisibleEmojiReaction(null)}
                    onCancel={() => setVisibleEmojiReaction(null)}
                  >
                    <EmojiPicker
                      width="100%"
                      skinTonesDisabled
                      previewConfig={{ showPreview: false }}
                      categories={emojisCategories}
                      emojiStyle={EmojiStyle.NATIVE}
                      emojiVersion="12"
                      searchDisabled
                      onEmojiClick={(emoji, event) => {
                        setVisibleEmojiReaction(null);
                        setVisibleReactionBar(null);
                        postMessageReaction(message.id, emoji.unified);
                      }}
                    />
                  </Modal>
                </div>
              );
            })}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MessageBubble;
